import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import Img1 from './assets/img1.png'
import Stars from './assets/stars.svg'

const data = [
    {
        text: "We’ve used many tools in the past, but nothing comes close to this platform’s versatility. It has empowered our marketing team to create and manage campaigns with ease, while offering deep insights into what’s working and what’s not. We’ve been able to reduce our cost-per-lead by 25% while increasing overall engagement. It’s essential for anyone serious about marketing automation.",
        image: 'https://i.pravatar.cc/150?img=1',
        name: "Sarah Jay",
        role: "Manufacturer"
    },
    {
        text: "This platform has completely transformed how I manage campaigns. I can now track every detail, from lead generation to conversion, with precision. It’s intuitive, fast, and has drastically reduced the time it takes to report performance metrics. I’ve seen a 30% increase in my outreach efficiency since using it!",
        image: 'https://i.pravatar.cc/150?img=4',
        name: "Admil Bron",
        role: "Promoter"
    },
    {
        text: "Working with agencies has never been smoother. The platform’s real-time reporting and collaborative features keep me in the loop without constant back-and-forth emails. It has improved our communication and allowed us to launch data-driven campaigns that consistently deliver better results. It’s become an essential part of our marketing strategy.",
        image: 'https://i.pravatar.cc/150?img=8',
        name: "Jeremy Farr",
        role: "Client"
    },
    {
        text: "Before using this platform, managing our marketing was overwhelming. Now, I can easily run promotions, track customer engagement, and analyze sales performance, all in one place. The insights we’ve gained have helped us target our marketing efforts more effectively, and the results speak for themselves—our sales have gone up 20% in just a few months!",
        image: 'https://i.pravatar.cc/150?img=9',
        name: "Emlon Banks",
        role: "Store owner"
    },
    {
        text: "As a supervisor, I need a tool that gives me complete visibility into my team’s progress. This marketing platform makes it easy to monitor campaign performance, assign tasks, and measure ROI. It has streamlined our workflow, allowing us to execute and optimize campaigns faster than ever before. It’s been a game-changer for our department!",
        image: 'https://i.pravatar.cc/150?img=11',
        name: "Ebenezer Maliu",
        role: "Supervisor"
    },
    {
        text: "Running a business is tough, and I need software that simplifies marketing without cutting corners. This platform does exactly that. Its intuitive design, coupled with powerful features like segmentation and analytics, makes it a no-brainer for any business owner looking to scale their marketing efforts. Our customer acquisition costs have dropped significantly since we started using it.",
        image: 'https://i.pravatar.cc/150?img=12',
        name: "Ahmed Bass",
        role: "Sales Rep."
    },
]

const Testimonials = () => {
  return (
    <Box px={"1rem"} maxW="1200px" mx="auto" mt={["4rem", "8rem"]}>
        <Heading fontWeight={["500", "600"]} mb="4rem" maxW={["270px", "100%"]} mx={"auto"} textAlign={"center"} color={"#1D2939"} fontSize={["1.5rem", "1.8rem"]}>Here&apos;s what our customers think</Heading>
        <Flex gap={"1rem"} overflowX={"scroll"}
        sx={
            { 
            '::-webkit-scrollbar':{
                  display:'none'
              }
            }
        }
        >
            {data?.map(({name, text, image, role}, index) => {
                return (
                    <Box flex={"1 0 265px"} position={"relative"} key={index} padding={"1.2rem"} bg="#FFF"borderRadius={"8px"} border="1px solid #FFCBCB">
                        <Image src={Stars} />
                        <Text mt="1.2rem" maxW={"95%"} fontSize={".75rem"} lineHeight={"16px"} mb="4rem">{text}</Text>
                        <Flex mt="1.5rem" alignItems={"center"} position={'absolute'} bottom={"5%"}>
                            <Box height={"40px"} width={"40px"} borderRadius={"50%"} backgroundImage={image} backgroundSize={"cover"} mr=".5rem" />
                            <Box>
                                <Text fontSize={".78rem"} color="#101928" fontWeight={"600"}>{name}</Text>
                                <Text fontSize={".78rem"} color="#344054">{role}</Text>
                            </Box>
                        </Flex>
                    </Box>
                )
            })}
        </Flex>
    </Box>
  )
}

export default Testimonials