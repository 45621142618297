import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

const data = [
    {
        question: "What is promorama?",
        answer: "is a comprehensive B2B marketing platform that allows businesses to efficiently manage campaigns, brand activations, and product pushes. It enables you to coordinate field promoters, supervisors, and other staff to ensure seamless execution, tracking, and reporting of your marketing activities."
    },
    {
        question: "How does promorama streamline campaign management?",
        answer: "Our software centralizes all aspects of campaign management. From planning to execution, you can assign tasks, monitor field promoters in real-time, track brand activations, and view comprehensive reports, ensuring that your campaigns are running smoothly and effectively."
    },
    {
        question: "HCan I track the performance of field promoters and supervisors?",
        answer: "Yes, Promorama offers real-time tracking and performance analytics for both field promoters and supervisors. You can monitor their locations, task completions, and overall effectiveness, ensuring you maximize your field resources."
    },
    {
        question: "How does the software handle reporting and analytics for campaigns?",
        answer: "Promorama provides detailed reports and data analytics for each campaign, activation, and brand push. You can view metrics such as engagement, sales impact, and promoter performance to assess the success of your efforts and optimize future campaigns."
    },
    {
        question: "Can I manage multiple campaigns and activations simultaneously?",
        answer: "Yes, Promorama allows you to manage multiple campaigns and brand activations at once. You can assign promoters and supervisors to different campaigns, set timelines, and track the progress of each campaign in real-time."
    },
    {
        question: "How can my field promoters use the platform in the field?",
        answer: "Field promoters can access [Software Name] through a mobile app, allowing them to receive tasks, check-in at activation sites, report progress, and communicate with supervisors—all in real-time. This ensures seamless coordination and reduces delays."
    },
    {
        question: "How secure is the data in Promorama?",
        answer: "Data security is a top priority at promorama. Our platform is built with enterprise-grade encryption, secure data storage, and strict access controls, ensuring that your campaign data and sensitive information are protected at all times."
    },
]

const Faq = () => {
  return (
    <Box
      px={["1rem", "3rem", "6rem"]}
      bg="#000000"
      pt="1.5rem"
    >
      <Box maxW={"800px"} mx="auto">

      <Accordion allowToggle allowMultiple={false} defaultIndex={[0]}>
      {data?.map(({question, answer}, index) => {
        return (
                <AccordionItem key={index} borderColor={"#E4E7EC"} border="none" borderBottom=".5px solid ">
                <h2>
                    <AccordionButton padding={".6rem 0"}>
                    <Box
                        color={"#E4E7EC"}
                        fontWeight="400"
                        fontSize={["1rem", "1.25rem"]}
                        padding={".6rem 0"}
                        as="span"
                        flex="1"
                        textAlign="left"
                        borderTop={"none"}
                        _expanded={{fontWeight: "500"}}
                    >
                        {question}
                    </Box>
                    <AccordionIcon color={"#E4E7EC"} />
                    </AccordionButton>
                </h2>
                <AccordionPanel
                    color={"#D0D5DD"}
                    fontSize={[".9rem", "1rem"]}
                    pb={4}
                    padding={".7rem 0 1.3rem 0"}
                    maxW="85%"
                >
                    {answer}
                </AccordionPanel>
                </AccordionItem>
        )
      })}
      </Accordion>
      </Box>
    </Box>
  );
};

export default Faq;
