import { Flex, Image, Text } from "@chakra-ui/react";
import Logo from '../../assets/logo1.png';
import Menu from '../../assets/menu.svg';
import PrimaryButton from "../primaryButton/primarybutton";
import {HiChevronDown} from 'react-icons/hi'
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../customDrawer/customDrawer";
import { useState } from "react";
import ClientForm from "../clientForm/clientForm";
import externalLinks from "../../utils/utils";

const navData = [
    {
        title: "About Us",
        url: "#",
        subMenu: [],
        type: 'page'
    },
    {
        title: "Features",
        url: "#",
        subMenu: [],
        type: 'scroll',
        pos: 2100
    },
    {
        title: "Pricing",
        url: "#",
        subMenu: [],
        type: 'scroll',
        pos: 10000
    },
    {
        title: "Contact Us",
        url: "contact",
        subMenu: [],
        type: 'page'
    },
    {
        title: "FAQs",
        url: "#",
        subMenu: [],
        type: 'scroll',
        pos: 10850
    },
]

const NavBar = () => {
  const navigate = useNavigate()
  const [triggerClose, setTriggerClose] = useState("");

  const handleDrawerMenu = (url) => {
    navigate(`/${url}`)
    setTriggerClose(Math.random()?.toString() + 'close')
  }

  const handleScroll = (pos) => {
    window.scrollTo({
        top: pos, // Position to scroll to vertically
        left: 0,  // Position to scroll to horizontally (0 keeps it unchanged)
        behavior: 'smooth' // Enables smooth scrolling
      });
      console.log('fires')
  }

  return (
    <>
    <Flex display={["none", "none", "flex", "flex"]} position={"fixed"} zIndex={"100"} width="100%" height={"75px"} borderBottom={".5px solid #1D2939"} boxShadow={"0px 2px 4px -1px #10192805"} bg="#252525" justifyContent={"space-between"} alignItems={"center"} px={["10px", "1rem", "2rem", "4rem"]}>
        <Image cursor={"pointer"} onClick={() => navigate('/')} src={Logo} />
        
        <Flex display={["none", "none", "flex", "flex"]} justifyContent={"space-between"} gap={"2rem"} alignItems={"center"}>
            {navData?.map(({title, subMenu, url, type, pos}) => {
                return (
                    <Text onClick={type === 'page' ? () => navigate(`/${url}`): () => handleScroll(pos)} key={title} whiteSpace={"nowrap"} cursor={"pointer"} fontWeight={"600"} fontSize={[".75rem", ".85rem"]} color={"#FFF"}>{title} {subMenu?.length > 0 && <HiChevronDown style={{color: "#FFF", display: "inline", transform: "translateY(3px)"}} />}</Text>
                )
            })}
        </Flex>

        <Flex alignItems={"center"} gap={"1.2rem"}>
            <a href={externalLinks.clientLogin} target="_blank" rel="noopener noreferrer">
            <Text whiteSpace={"nowrap"} cursor={"pointer"} fontWeight={"600"} fontSize={[".75rem", ".85rem"]} color={"#FFF"}>Log in</Text>
            </a>

            <CustomDrawer
            position={"right"}
            title={"Get started with PromoRama"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <PrimaryButton bg="#FFF" py=".8rem" height="36px !important" color="#101828 !important" text="Sign up" />
            }
            content={
                <ClientForm setTriggerClose={setTriggerClose} />
            }
            />
        </Flex>
    </Flex>




    <Flex display={["flex", "flex", "none", "none"]} position={"fixed"} zIndex={"100"} width="100%" height={"75px"} borderBottom={".5px solid #1D2939"} boxShadow={"0px 2px 4px -1px #10192805"} bg="#252525" justifyContent={"space-between"} alignItems={"center"} px={["10px", "1rem", "2rem", "4rem"]}>
        <Image cursor={"pointer"} onClick={() => navigate('/')} src={Logo} />


        <CustomDrawer
        position={"top"}
        title={<Image transform={"translateY(-.4rem)"} cursor={"pointer"} src={Logo} />}
        triggerClose={triggerClose}
        setTriggerClose={setTriggerClose}
        bg="#252525"
        closeButtonColor="#FFF"
        toggleElement={
            <Image transform={"scale(1.2)"} cursor={"pointer"} src={Menu} />
        }
        content={
            <>
            <Flex direction={"column"} justifyContent={"space-between"} gap={"2rem"} alignItems={"center"} py="2rem" mb="2rem" borderTop="1px solid #344054" borderBottom="1px solid #344054">
                {navData?.map(({title, subMenu, url}) => {
                    return (
                        <Text onClick={() => handleDrawerMenu(url)} key={title} whiteSpace={"nowrap"} cursor={"pointer"} fontWeight={"600"} fontSize={".95rem"} color={"#FFF"}>{title} {subMenu?.length > 0 && <HiChevronDown style={{color: "#FFF", display: "inline", transform: "translateY(3px)"}} />}</Text>
                    )
                })}
            </Flex>

            <Flex direction={"column"} alignItems={"center"} gap={"1.2rem"}>
                <a href={externalLinks.clientLogin} target="_blank" rel="noopener noreferrer">
                <Text whiteSpace={"nowrap"} cursor={"pointer"} fontWeight={"600"} fontSize={".95rem"} color={"#FFF"}>Log in</Text>
                </a>

                <CustomDrawer
                position={"right"}
                title={"Get started with PromoRama"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <PrimaryButton bg="#FFF" py=".8rem" width="180px" height="45px !important" color="#101828 !important" text="Sign up" />
                }
                content={
                    <ClientForm setTriggerClose={setTriggerClose} />
                }
                />
            </Flex>
            <br /><br />
            </>
        }
        />
    </Flex>
    </>
  )
}

export default NavBar;
